/* AUTO-GENERATED FILE - DO NOT EDIT */

/* vue-src/site/@astralito/components.js */
/* vue-src/site/cc-operator-decidir/components.js */
/* vue-src/site/cc-operator-first-data/components.js */
/* vue-src/site/shop-payment-cash/components.js */
/* vue-src/site/shop-payment-credit-card/components.js */
/* vue-src/site/shop-payment-mercadopago/components.js */
/* vue-src/site/shop-payment-paypal/components.js */
/* vue-src/site/shop-payment-transbank/components.js */
/* vue-src/site/shop/components.js */
/* vue-src/site/site/components.js */

import Vue from 'vue'

export const Home = require('./@astralito/home/view_Home.vue').default
export const AboutUs = () => import(/* webpackChunkName: "others" */ './@astralito/others/view_AboutUs.vue')
export const Faq = () => import(/* webpackChunkName: "others" */ './@astralito/others/view_Faq.vue')
export const OurGuarantee = () => import(/* webpackChunkName: "others" */ './@astralito/others/view_OurGuarantee.vue')
export const Privacy = () => import(/* webpackChunkName: "others" */ './@astralito/others/view_Privacy.vue')
export const TermsAndConditions = () => import(/* webpackChunkName: "others" */ './@astralito/others/view_TermsAndConditions.vue')
export const FreeIssue_ABC = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue-ABC.vue')
export const FreeIssue_Amistad = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue-Amistad.vue')
export const FreeIssue_Amor = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue-Amor.vue')
export const FreeIssue_AstralitoAdultos = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue-AstralitoAdultos.vue')
export const FreeIssue_LetterSantaClous = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue-LetterSantaClous.vue')
export const FreeIssue_Tasks = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue-Tasks.vue')
export const FreeIssue = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssue.vue')
export const FreeIssueThanks = () => import(/* webpackChunkName: "others" */ './@astralito/others/free-issue/view_FreeIssueThanks.vue')
export const Ant = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_Ant.vue')
export const AntEnglish = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_AntEnglish.vue')
export const AntPortuguese = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_AntPortuguese.vue')
export const Chicken = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_Chicken.vue')
export const ChickenEnglish = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_ChickenEnglish.vue')
export const ChickenPortuguese = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_ChickenPortuguese.vue')
export const Crocodile = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_Crocodile.vue')
export const CrocodileEnglish = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_CrocodileEnglish.vue')
export const CrocodilePortuguese = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_CrocodilePortuguese.vue')
export const Lion = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_Lion.vue')
export const LionEnglish = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_LionEnglish.vue')
export const LionPortuguese = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_LionPortuguese.vue')
export const Octopus = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_Octopus.vue')
export const OctopusEnglish = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_OctopusEnglish.vue')
export const OctopusPortuguese = () => import(/* webpackChunkName: "animals" */ './@astralito/animals/view_OctopusPortuguese.vue')
export const CreditCardPayment = () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/view_CreditCardPayment.vue')
export const Checkout = () => import(/* webpackChunkName: "checkout" */ './shop/checkout/view_Checkout.vue')
export const ShopList = () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/view_ShopList.vue')
export const Product = () => import(/* webpackChunkName: "product" */ './shop/catalog/product/view_Product.vue')
export const Upsells = () => import(/* webpackChunkName: "product" */ './shop/catalog/upsell/view_Upsells.vue')
export const SharedContentFiles = () => import(/* webpackChunkName: "account" */ './shop/account/view_SharedContentFiles.vue')
export const UserAccount = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserAccount.vue')
export const UserContentFiles = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserContentFiles.vue')
export const UserFavorites = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserFavorites.vue')
export const UserOrderDetail = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserOrderDetail.vue')
export const UserOrders = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserOrders.vue')

const c = []
const r = (a,b) => {
	c.push(a)
	Vue.component(a,b)
}
export const componentExists = (name) => c.includes(name)
export const getComponentsNames = () => [...c]
export const filterComponents = (re) => c.filter(name => name.match(re))
r('Home-A-BooksSliders', require('./@astralito/home/comp_Home-A-BooksSliders.vue').default)
r('Home-A-Sliders', require('./@astralito/home/comp_Home-A-Sliders.vue').default)
r('Home-B-Cards', require('./@astralito/home/comp_Home-B-Cards.vue').default)
r('Home-C-HowWorks', require('./@astralito/home/comp_Home-C-HowWorks.vue').default)
r('Home-D-Info', require('./@astralito/home/comp_Home-D-Info.vue').default)
r('Home-E-BigImage', require('./@astralito/home/comp_Home-E-BigImage.vue').default)
r('Home-F-MoreInfo', require('./@astralito/home/comp_Home-F-MoreInfo.vue').default)
r('Home-G-Cards', require('./@astralito/home/comp_Home-G-Cards.vue').default)
r('Home-H-Reviews', require('./@astralito/home/comp_Home-H-Reviews.vue').default)
r('Home-I-Newsletter', require('./@astralito/home/comp_Home-I-Newsletter.vue').default)
r('Home-ImageCard', require('./@astralito/home/comp_Home-ImageCard.vue').default)
r('NewsLetterForm', require('./@astralito/home/comp_NewsLetterForm.vue').default)
r('AppBar', require('./@astralito/layout/comp_AppBar.vue').default)
r('Footer', require('./@astralito/layout/comp_Footer.vue').default)
r('MobileMenuDrawer', require('./@astralito/layout/comp_MobileMenuDrawer.vue').default)
r('Button', require('./@astralito/common/comp_Button.vue').default)
r('ExpansionPanels', require('./@astralito/common/comp_ExpansionPanels.vue').default)
r('OtherPageLayout', () => import(/* webpackChunkName: "others" */ './@astralito/others/comp_OtherPageLayout.vue'))
r('BookEditor', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/comp_BookEditor.vue'))
r('BookPageWrapper', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/comp_BookPageWrapper.vue'))
r('BookPreview', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/comp_BookPreview.vue'))
r('BookPreviewBallsLoader', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/comp_BookPreviewBallsLoader.vue'))
r('CarouselBook', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/comp_CarouselBook.vue'))
r('FlipBook', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/comp_FlipBook.vue'))
r('unused--view_BookEditor.vue', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor/unused--view_BookEditor.vue'))
r('BookEditorCustomizationInputs-aPerfectPlan', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-aPerfectPlan.vue'))
r('BookEditorCustomizationInputs-astralito-adultos', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-astralito-adultos.vue'))
r('BookEditorCustomizationInputs-astralito', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-astralito.vue'))
r('BookEditorCustomizationInputs-colors', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-colors.vue'))
r('BookEditorCustomizationInputs-colors2', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-colors2.vue'))
r('BookEditorCustomizationInputs-egresaditos', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-egresaditos.vue'))
r('BookEditorCustomizationInputs-fromName-toName-lang-inscription', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-fromName-toName-lang-inscription.vue'))
r('BookEditorCustomizationInputs-mothers-day', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-mothers-day.vue'))
r('BookEditorCustomizationInputs-name-gender-lang-inscription', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-name-gender-lang-inscription.vue'))
r('BookEditorCustomizationInputs-name-lang-inscription', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-name-lang-inscription.vue'))
r('BookEditorCustomizationInputs-nameCoverInscription', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-nameCoverInscription.vue'))
r('BookEditorCustomizationInputs-onlyInscription', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-onlyInscription.vue'))
r('BookEditorCustomizationInputs-primeraComunion', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-primeraComunion.vue'))
r('BookEditorCustomizationInputs-withoutCustomization', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/comp_BookEditorCustomizationInputs-withoutCustomization.vue'))
r('BookEditor-Tabs', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditor-Tabs.vue'))
r('BookEditorInput-ColorsSelector', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-ColorsSelector.vue'))
r('BookEditorInput-CoverSelector', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-CoverSelector.vue'))
r('BookEditorInput-DatePicker', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-DatePicker.vue'))
r('BookEditorInput-GenderCharacters', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-GenderCharacters.vue'))
r('BookEditorInput-Image-Cropper', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-Image-Cropper.vue'))
r('BookEditorInput-Image-CropperDialog', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-Image-CropperDialog.vue'))
r('BookEditorInput-Image', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-Image.vue'))
r('BookEditorInput-LangSelect', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-LangSelect.vue'))
r('BookEditorInput-TextField', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorInput-TextField.vue'))
r('BookEditorTab-Inscription', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorTab-Inscription.vue'))
r('BookEditorTab-UserImage', () => import(/* webpackChunkName: "book" */ './@astralito/book/book-editor-customization-inputs/tabs-template/comp_BookEditorTab-UserImage.vue'))
r('AnimalHeader', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_AnimalHeader.vue'))
r('AnimalPicSection', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_AnimalPicSection.vue'))
r('DataSection', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_DataSection.vue'))
r('EnglishSongSection', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_EnglishSongSection.vue'))
r('PortugueseSongSection', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_PortugueseSongSection.vue'))
r('SongSection', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_SongSection.vue'))
r('StarsSection', () => import(/* webpackChunkName: "animals" */ './@astralito/animals/components/comp_StarsSection.vue'))
r('unused--comp_ProductPageCustomizationInputs-aPerfectPlan.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-aPerfectPlan.vue'))
r('unused--comp_ProductPageCustomizationInputs-astralito-adultos.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-astralito-adultos.vue'))
r('unused--comp_ProductPageCustomizationInputs-astralito.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-astralito.vue'))
r('unused--comp_ProductPageCustomizationInputs-colors.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-colors.vue'))
r('unused--comp_ProductPageCustomizationInputs-fromName-toName-lang-inscription.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-fromName-toName-lang-inscription.vue'))
r('unused--comp_ProductPageCustomizationInputs-mothers-day.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-mothers-day.vue'))
r('unused--comp_ProductPageCustomizationInputs-name-gender-lang-inscription.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-name-gender-lang-inscription.vue'))
r('unused--comp_ProductPageCustomizationInputs-name-lang-inscription.vue', () => import(/* webpackChunkName: "product" */ './@astralito/book/product-page-customization-inputs/unused--comp_ProductPageCustomizationInputs-name-lang-inscription.vue'))
r('CreditCard-OperatorResolver-Decidir', () => import(/* webpackChunkName: "creditCard" */ './cc-operator-decidir/comp_CreditCard-OperatorResolver-Decidir.vue'))
r('CreditCard-OperatorResolver-FirstData', () => import(/* webpackChunkName: "creditCard" */ './cc-operator-first-data/comp_CreditCard-OperatorResolver-FirstData.vue'))
r('Cash-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-cash/comp_Cash-Payment-CheckoutConfirmCardContent.vue'))
r('Cash-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-cash/comp_Cash-Payment-CheckoutOptions.vue'))
r('CashCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-cash/comp_CashCardContent.vue'))
r('CreditCard-CheckoutConfirmButton', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-CheckoutConfirmButton.vue'))
r('CreditCard-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-Payment-CheckoutConfirmCardContent.vue'))
r('CreditCard-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-Payment-CheckoutOptions.vue'))
r('CreditCard-Payment-InstructionsCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-Payment-InstructionsCardContent.vue'))
r('CreditCardApp', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardApp.vue'))
r('CreditCardCheckoutOptionContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardCheckoutOptionContent.vue'))
r('CreditCardNumberForm', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardNumberForm.vue'))
r('CreditCardPlanBox', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardPlanBox.vue'))
r('CreditCardPlanSelector', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardPlanSelector.vue'))
r('CreditCardTransactionDetail', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardTransactionDetail.vue'))
r('MercadoPago-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPago-Payment-CheckoutConfirmCardContent.vue'))
r('MercadoPago-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPago-Payment-CheckoutOptions.vue'))
r('MercadoPago-Payment-InstructionsCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPago-Payment-InstructionsCardContent.vue'))
r('MercadoPagoCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPagoCardContent.vue'))
r('Paypal-CheckoutConfirmButton', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_Paypal-CheckoutConfirmButton.vue'))
r('Paypal-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_Paypal-Payment-CheckoutConfirmCardContent.vue'))
r('Paypal-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_Paypal-Payment-CheckoutOptions.vue'))
r('PaypalCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_PaypalCardContent.vue'))
r('Transbank-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_Transbank-Payment-CheckoutConfirmCardContent.vue'))
r('Transbank-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_Transbank-Payment-CheckoutOptions.vue'))
r('Transbank-Payment-InstructionsCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_Transbank-Payment-InstructionsCardContent.vue'))
r('TransbankCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_TransbankCardContent.vue'))
r('App', require('./shop/comp_App.vue').default)
r('AutoLazy', require('./shop/common/comp_AutoLazy.vue').default)
r('AutoLazyLoader', require('./shop/common/comp_AutoLazyLoader.vue').default)
r('CardLayout', require('./shop/common/comp_CardLayout.vue').default)
r('Carousel', require('./shop/common/comp_Carousel.vue').default)
r('CountryPhoneCodeSelector', require('./shop/common/comp_CountryPhoneCodeSelector.vue').default)
r('DiscountText', require('./shop/common/comp_DiscountText.vue').default)
r('PriceText', require('./shop/common/comp_PriceText.vue').default)
r('QtyInput', require('./shop/common/comp_QtyInput.vue').default)
r('FacebookAuthButton', require('./shop/login/comp_FacebookAuthButton.vue').default)
r('ForgotForm', require('./shop/login/comp_ForgotForm.vue').default)
r('GoogleAuthButton', require('./shop/login/comp_GoogleAuthButton.vue').default)
r('LoginDrawer', require('./shop/login/comp_LoginDrawer.vue').default)
r('LoginForm', require('./shop/login/comp_LoginForm.vue').default)
r('LoginForms', require('./shop/login/comp_LoginForms.vue').default)
r('SignupForm', require('./shop/login/comp_SignupForm.vue').default)
r('ThirdPartyLoginsButtons', require('./shop/login/comp_ThirdPartyLoginsButtons.vue').default)
r('CartDrawer', require('./shop/cart/comp_CartDrawer.vue').default)
r('CartItem', require('./shop/cart/comp_CartItem.vue').default)
r('PriceLayout', require('./shop/catalog/common/comp_PriceLayout.vue').default)
r('ProductDiscount', require('./shop/catalog/common/comp_ProductDiscount.vue').default)
r('ProductPrevPrice', require('./shop/catalog/common/comp_ProductPrevPrice.vue').default)
r('ProductPrice', require('./shop/catalog/common/comp_ProductPrice.vue').default)
r('ProductsCarousel', require('./shop/catalog/common/comp_ProductsCarousel.vue').default)
r('ProductsCarouselSection', require('./shop/catalog/common/comp_ProductsCarouselSection.vue').default)
r('ProductsLayout', require('./shop/catalog/common/comp_ProductsLayout.vue').default)
r('ProductWishlistToggler', require('./shop/catalog/common/comp_ProductWishlistToggler.vue').default)
r('SkuChip', require('./shop/catalog/common/comp_SkuChip.vue').default)
r('ProductCard', require('./shop/catalog/product-card/comp_ProductCard.vue').default)
r('ProductCardImage', require('./shop/catalog/product-card/comp_ProductCardImage.vue').default)
r('OrderSummary-Item', () => import(/* webpackChunkName: "order" */ './shop/order/comp_OrderSummary-Item.vue'))
r('OrderSummary-Totals', () => import(/* webpackChunkName: "order" */ './shop/order/comp_OrderSummary-Totals.vue'))
r('OrderSummary', () => import(/* webpackChunkName: "order" */ './shop/order/comp_OrderSummary.vue'))
r('PaymentInstructions', () => import(/* webpackChunkName: "order" */ './shop/order/comp_PaymentInstructions.vue'))
r('CheckoutApp', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/comp_CheckoutApp.vue'))
r('CheckoutLayout', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/comp_CheckoutLayout.vue'))
r('AddressDialog', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_AddressDialog.vue'))
r('CheckoutCollapsedSummary', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CheckoutCollapsedSummary.vue'))
r('CheckoutPaymentCardContent', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CheckoutPaymentCardContent.vue'))
r('CheckoutStepLayout', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CheckoutStepLayout.vue'))
r('CountrySelector', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CountrySelector.vue'))
r('DeliveryOptionCardContent-PickupPoint', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_DeliveryOptionCardContent-PickupPoint.vue'))
r('DeliveryOptionCardContent-Shipping', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_DeliveryOptionCardContent-Shipping.vue'))
r('DeliveryOptionCardContent', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_DeliveryOptionCardContent.vue'))
r('CheckoutStep-confirm', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-confirm.vue'))
r('CheckoutStep-contact', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-contact.vue'))
r('CheckoutStep-delivery', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-delivery.vue'))
r('CheckoutStep-discount', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-discount.vue'))
r('CheckoutStep-init', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-init.vue'))
r('CheckoutStep-payment', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-payment.vue'))
r('CheckoutStep-signin', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-signin.vue'))
r('CheckoutConfirmCard-contact', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-contact.vue'))
r('CheckoutConfirmCard-delivery', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-delivery.vue'))
r('CheckoutConfirmCard-discount', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-discount.vue'))
r('CheckoutConfirmCard-payment', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-payment.vue'))
r('DefaultProducts', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_DefaultProducts.vue'))
r('ShopFilters', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_ShopFilters.vue'))
r('ShopFiltersGroup', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_ShopFiltersGroup.vue'))
r('ProductActions', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductActions.vue'))
r('ProductImagesCarousel', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductImagesCarousel.vue'))
r('ProductImagesDialog', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductImagesDialog.vue'))
r('ProductImagesThumb', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductImagesThumb.vue'))
r('ProductStockAvailabilityMessage', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductStockAvailabilityMessage.vue'))
r('ProductStockQtyMessage', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductStockQtyMessage.vue'))
r('TruncatedText', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_TruncatedText.vue'))
r('VariantsSelector', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_VariantsSelector.vue'))
r('UpsellProductCard', () => import(/* webpackChunkName: "product" */ './shop/catalog/upsell/comp_UpsellProductCard.vue'))
r('ChangePassword', () => import(/* webpackChunkName: "account" */ './shop/account/comp_ChangePassword.vue'))
r('ContactData', () => import(/* webpackChunkName: "account" */ './shop/account/comp_ContactData.vue'))
r('OrderDigitalItemsCard', () => import(/* webpackChunkName: "account" */ './shop/account/comp_OrderDigitalItemsCard.vue'))
r('OrderListCard', () => import(/* webpackChunkName: "account" */ './shop/account/comp_OrderListCard.vue'))
r('ShareContentFileBtn', () => import(/* webpackChunkName: "account" */ './shop/account/comp_ShareContentFileBtn.vue'))
r('UserContentFiles-BookPreviewDialog', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserContentFiles-BookPreviewDialog.vue'))
r('UserContentFilesDisplay', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserContentFilesDisplay.vue'))
r('UserData', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserData.vue'))
r('UserpanelDrawer', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserpanelDrawer.vue'))
r('UserpanelLayout', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserpanelLayout.vue'))
r('Autocomplete', require('./site/common/comp_Autocomplete.vue').default)
r('Container', require('./site/common/comp_Container.vue').default)
r('DatePickerDialog', require('./site/common/comp_DatePickerDialog.vue').default)
r('MagicButton', require('./site/common/comp_MagicButton.vue').default)
r('Media', require('./site/common/comp_Media.vue').default)
r('RatioImage', require('./site/common/comp_RatioImage.vue').default)
r('SafeLink', require('./site/common/comp_SafeLink.vue').default)
r('Select', require('./site/common/comp_Select.vue').default)
r('Textarea', require('./site/common/comp_Textarea.vue').default)
r('TextField', require('./site/common/comp_TextField.vue').default)
r('Validator', require('./site/common/comp_Validator.vue').default)