import { render, staticRenderFns } from "./comp_Home-G-Cards.vue?vue&type=template&id=2b0369d6&scoped=true&"
import script from "./comp_Home-G-Cards.vue?vue&type=script&lang=js&"
export * from "./comp_Home-G-Cards.vue?vue&type=script&lang=js&"
import style0 from "./comp_Home-G-Cards.vue?vue&type=style&index=0&id=2b0369d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b0369d6",
  null
  
)

export default component.exports